import { sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Category',
      flex: 1,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => {
            if (p.parent?.name === 'Council' && p.name === 'Ordinary member') {
              return `<div>${p.name}</div>`;
            }

            return `<div>${p.parent?.name}</div>`;
          })
          .join('');
      },
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => {
            if (p.parent.name === 'Council' && p.name === 'Ordinary member') {
              return '<div>&nbsp;</div>';
            }

            return `<div>${p.name}</div>`;
          })
          .join('');
      },
    },
  ];
}
